import React from 'react';
import { graphql } from 'gatsby';

import { styleVars } from '../../lib/helpers/style';
import { Heading, PageContainer, MainContainer, Link, LeftContainer } from '../../common';
import OfficeListCard from '../../components/OfficeHub/OfficeListCard';
import GovernmentSchema from '../../components/OfficeHub/GovernmentSchema';

export const query = graphql`
  query($nid: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    drupal {
      office: nodeById(id: $nid) {
        entityUrl {
          path
        }
        ... on Drupal_NodeOfficePage {
          title
          fieldOfficeAddress {
            locality
            postalCode
            addressLine1
            addressLine2
          }
          fieldOfficeServices {
            entity {
              ... on Drupal_TaxonomyTermOfficeService {
                name
              }
            }
          }
          fieldOfficeNoticeAlert
          fieldOfficeNoticeInfo
          fieldPhoneNumber
          fieldOfficeHours {
            value
            format
            processed
          }
        }
      }
    }
  }
`;

export default ({
  pageContext: { officeHubUrl },
  data: {
    site: { siteMetadata },
    drupal: { office },
  },
}) => {
  const { title } = office;

  return (
    <>
      <GovernmentSchema office={office} siteUrl={siteMetadata.siteUrl} />
      <MainContainer row className={`py-8 py-${styleVars.mobileBreakpoint}-9`}>
        <LeftContainer />
        <PageContainer>
          <Heading as="h1" color="darkYellow">
            {title}
          </Heading>
          <div className="mt-5">
            <Link href={officeHubUrl}>View all office locations</Link>
          </div>
        </PageContainer>
      </MainContainer>
      <div className={`form-widget pt-8 pt-${styleVars.mobileBreakpoint}-9 pb-10`}>
        <MainContainer row>
          <LeftContainer />
          <PageContainer>
            <OfficeListCard data={office} />
          </PageContainer>
        </MainContainer>
      </div>
    </>
  );
};
