import React from 'react'
import { Helmet } from 'react-helmet'

import { schema } from '../../lib/helpers/seo'
import Logo from '../../assets/logo/tabc-badge-lg.png'

export default ({ siteUrl, office: { title, fieldOfficeAddress, fieldPhoneNumber, entityUrl } }) => {
  const { addressLine1, addressLine2 = '', locality } = fieldOfficeAddress

  const govtSchema = {
    address: {
      "@type": "PostalAddress",
      "streetAddress": `${addressLine1} ${addressLine2}`,
      "addressLocality": locality,
      "addressRegion": "Texas",
      "addressCountry": "USA"
    },
    branchOf: {
      "@type": "Organization",
      "name": "Texas Alcoholic Beverage Commission"
    },
    logo: {
      "@type": "ImageObject",
      "contentUrl": `${siteUrl}${Logo}`
    },
    telephone: fieldPhoneNumber,
    image: `${siteUrl}${Logo}`,
    name: title,
    url: `${siteUrl}${entityUrl.path}`
  }


  return (
    <Helmet>
      <script type="application/ld+json">
        {schema("GovernmentOffice", govtSchema)}
      </script>
    </Helmet>
  )
}

